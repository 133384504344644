import React, { useEffect } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Head from "../components/head"
import PageLayout from "../components/apcpagelayout"

const PastEvent = () => {
  useEffect(() => {
    sessionStorage.removeItem("activeDate")
    sessionStorage.removeItem("scrollPosition")
    sessionStorage.removeItem("activeGreen")
    sessionStorage.removeItem("activeOrange")
    sessionStorage.removeItem("activePink")
    sessionStorage.removeItem("activePurple")
    sessionStorage.removeItem("activeBlue")
    sessionStorage.removeItem("activeLavender")
    sessionStorage.removeItem("activeRed")
    sessionStorage.removeItem("activeViolet")
    sessionStorage.removeItem("activeLima")
  })
  const data = useStaticQuery(graphql`
    query {
      allContentfulEvents(
        filter: { node_locale: { eq: "en-US" } }
        sort: { fields: endDate, order: DESC }
      ) {
        edges {
          node {
            title
            eventName
            backgroundImage {
              file {
                url
              }
            }
            slug
            status
            startDate(formatString: "MMMM D")
            eventEndDate: endDate(formatString: "D, YYYY")
            endDate(formatString: "MMMM D, YYYY")
            eventStartMonth: startDate(formatString: "MMMM")
            eventEndMonth: endDate(formatString: "MMMM")
            city
            learnMoreExternal
            learnMoreUrl
          }
        }
      }
    }
  `)

  return (
    <PageLayout
      title="PAST EVENTS"
      backgroundImage={
        data?.allContentfulEvents?.edges[0]?.node?.backgroundImage
      }
    >
      <Head title={`Kafka Summit - Past Events`} />
      <div className="past_event_body">
        {data &&
          data?.allContentfulEvents?.edges.map((edge, index) => {
            if (
              edge?.node?.status === "Completed" ||
              edge?.node?.status === "Cancelled"
            ) {
              return (
                <>
                  {!edge?.node?.learnMoreExternal && (
                    <Link
                      to={
                        edge?.node?.status === "Completed"
                          ? `/${edge?.node?.slug}`
                          : ""
                      }
                      className="past_event_thumb"
                      key={index}
                      style={{
                        pointerEvents:
                          edge?.node?.status === "Cancelled" ? "none" : "",
                      }}
                    >
                      <div className="past_event_thumb_col1">
                        <h2 className="past_event_title">
                          {edge?.node?.eventName}{" "}
                          {edge?.node?.endDate.split(",")[1]}{" "}
                          {edge?.node?.status === "Cancelled" && (
                            <span> - Cancelled</span>
                          )}
                        </h2>
                        <p className="past_event_dateplace">
                          {edge?.node?.startDate === null
                            ? edge?.node?.endDate
                            : edge?.node?.eventStartMonth ===
                              edge?.node?.eventEndMonth
                            ? `${edge?.node?.startDate} - ${edge?.node?.eventEndDate}`
                            : `${edge?.node?.startDate} - ${edge?.node?.endDate}`}
                          <br />
                        </p>
                      </div>
                      {edge?.node?.status !== "Cancelled" && (
                        <div className="past_event_thumb_col2"></div>
                      )}
                    </Link>
                  )}
                  {edge?.node?.learnMoreExternal && (
                    <a
                      href={
                        edge?.node?.status === "Completed"
                          ? `${edge?.node?.learnMoreUrl}`
                          : ""
                      }
                      className="past_event_thumb"
                      key={index}
                      style={{
                        pointerEvents:
                          edge?.node?.status === "Cancelled" ? "none" : "",
                      }}
                      target="_blank"
                    >
                      <div className="past_event_thumb_col1">
                        <h2 className="past_event_title">
                          {edge?.node?.eventName}{" "}
                          {edge?.node?.endDate.split(",")[1]}{" "}
                          {edge?.node?.status === "Cancelled" && (
                            <span> - Cancelled</span>
                          )}
                        </h2>
                        <p className="past_event_dateplace">
                          {edge?.node?.startDate === null
                            ? edge?.node?.endDate
                            : edge?.node?.eventStartMonth ===
                              edge?.node?.eventEndMonth
                            ? `${edge?.node?.startDate} - ${edge?.node?.eventEndDate}`
                            : `${edge?.node?.startDate} - ${edge?.node?.endDate}`}
                          <br />
                        </p>
                      </div>
                      {edge?.node?.status !== "Cancelled" && (
                        <div className="past_event_thumb_col2"></div>
                      )}
                    </a>
                  )}
                </>
              )
            }
          })}
      </div>
    </PageLayout>
  )
}
export default PastEvent
